<template>
  <div>
    <p class="h4">
      <i class="fas fa-lightbulb mr-2"></i>Predicciones generadas
    </p>
    <div class="table-responsive">
      <table class="table table-striped">
        <caption>Predicciones que han sido generadas</caption>
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Fecha</th>
            <th scope="col">Tema</th>
            <th scope="col">Tipo de Impugnación</th>
            <th scope="col">Ponente</th>
            <th scope="col">Modalidades</th>
            <th scope="col">Detalles</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(prediccion, index) in elementos" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ prediccion.fecha }}</td>
            <td>{{ prediccion.tema }}</td>
            <td>{{ prediccion.tipoImpugnacion }}</td>
            <td>{{ prediccion.ponente }}</td>
            <td>{{ prediccion.modalidades }}</td>
            <td>
              <button class="btnAccion" @click="mostrarDetalles(prediccion)">
                Ver detalles
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal para mostrar detalles -->
    <div class="modal" id="detallesModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detalles de la predicción</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <template v-if="detalleSeleccionado">
              <p><strong>Días transcurridos:</strong> {{ detalleSeleccionado.diasTranscurridos }}</p>
              <p><strong>Votación:</strong> {{ detalleSeleccionado.votacion }}</p>
              <p><strong>Sentido:</strong> {{ detalleSeleccionado.sentido }}</p>
            </template>
            <template v-else>
              <p>No hay detalles seleccionados.</p>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
    <Modal :show="showModal" :modalHeadMessage="modalHeadMessage" :modalMessage="modalMessage" @close="showModal = false"></Modal>
  </div>
</template>


  <script>
  import { auth, fr } from "../../firebase";
  import fn from "../../libs/index";
  import { mapMutations, mapActions } from "vuex";
  import Modal from '../Modal/ModalFormats/ModalBuild.vue';
  
  export default {
    name: "PrediccionesGenerados",
    components: {
      Modal
    },
    mounted() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.getFormatos();
          this.getPredicciones();
        }
      });
    },
    beforeDestroy() {
      
    },
    data() {
      return {
        voucherSize: 0,
        detalleSeleccionado: null,
        cargandoVoucher: false,
        avance: 0,
        voucherIMG: "",
        elementos: [],
        unsuscribe: null,
        showModal: false,
        modalHeadMessage: null,
        modalMessage: null,
        urlProd:
          "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
        urlLocal: "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
      };
    },
    computed: {
      url() {
        return process.env.NODE_ENV == "development"
          ? this.urlLocal
          : this.urlProd;
      },
    },
    methods: {
      ...mapMutations({
        setVoucherBand: "generals/setVoucherBand",
        setInfoVoucherVisualizacion: "generals/setInfoVoucherVisualizacion",
      }),
      ...mapActions({
        PDF: "contenido/generarPDF",
      }),
      verVoucher(uid) {
        window.$("#vocherPreview").modal("show");
        const [consulta] = this.elementos.filter((c) => c.uid == uid);
        this.setInfoVoucherVisualizacion({
          fecha: consulta.fecha_registro,
          url: consulta.urlVocher,
        });
      },

      formatTimestamp(timestamp) {
        const date = timestamp.toDate();
        const day = date.getDate().toString().padStart(2, '0'); // Día con dos dígitos
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mes con dos dígitos
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      },

      fechaFormatoCHAT(fecha) {
        const months = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];

        try {
            const dia = fecha.getDate();
            const mes = months[fecha.getMonth()];
            const año = fecha.getFullYear();

            return `${dia} de ${mes} de ${año}`;
        } catch (error) {
            console.error('Fecha inválida', error);
            return 'Fecha inválida';
        }
      },

      async getPredicciones() {
        try {
          const usuarioId = auth.currentUser.uid;
          const prediccionesRef = fr.collection('predicciones');

          // Obtener todos los documentos de la colección 'predicciones'
          const snapshot = await prediccionesRef.get();

          const predicciones = [];

          // Iterar sobre cada documento de 'predicciones'
          for (const doc of snapshot.docs) {

            // Verificar si el documento tiene subcolecciones 'resultados'
            const resultadosSnapshot = await doc.ref.collection('resultados')
              .where('idUsuario', '==', usuarioId)
              .get();

            // Si hay resultados para el usuario, procesarlos
            if (!resultadosSnapshot.empty) {
              resultadosSnapshot.forEach((resultadosDoc) => {
                const data = resultadosDoc.data();
                const fechaFormat = this.formatTimestamp(data.fechaRegistro); 
                console.log(fechaFormat)

                predicciones.push({
                  tema: data.tema,
                  fecha: fechaFormat,
                  tipoImpugnacion: data.tipoImpugnacion,
                  ponente: data.ponente,
                  modalidades: data.modalidades,
                  diasTranscurridos: data.diasTranscurridos,
                  votacion: data.votacion,
                  sentido: data.sentido,
                });
              });
            }
          }

          predicciones.sort((a, b) => {
            const fechaA = new Date(
                parseInt(a.fecha.substring(6, 10)),   
                parseInt(a.fecha.substring(3, 5)) - 1,
                parseInt(a.fecha.substring(0, 2))     
            );
            const fechaB = new Date(
                parseInt(b.fecha.substring(6, 10)),
                parseInt(b.fecha.substring(3, 5)) - 1,
                parseInt(b.fecha.substring(0, 2))
            );

            return fechaB - fechaA;
          });


          //predicciones.reverse();

          setTimeout(() => {
            this.elementos = predicciones;
          }, 0);

        } catch (error) {
          console.error("Error al obtener predicciones:", error);
        }
      },

      mostrarDetalles(prediccion) {
        this.detalleSeleccionado = {
          diasTranscurridos: prediccion.diasTranscurridos,
          votacion: prediccion.votacion,
          sentido: prediccion.sentido,
        };
        if (this.detalleSeleccionado.votacion == 1){
          this.detalleSeleccionado.votacion = "Mayoría";
        }else{
          this.detalleSeleccionado.votacion  = "Unanimidad";
        } 
        if (this.detalleSeleccionado.sentido == 1){
          this.detalleSeleccionado.sentido = "Infundado ";
        }else{
          this.detalleSeleccionado.sentido = "Fundado ";
        }
        // Parsear la fecha en formato DD/MM/YYYY
        const [day, month, year] = prediccion.fecha.split('/');
        const fechaT = new Date(`${year}-${month}-${day}`);

        // Calcular la nueva fecha añadiendo los días transcurridos
        const nuevaFecha = new Date(fechaT.getTime() + (prediccion.diasTranscurridos * 24 * 60 * 60 * 1000));

        // Formatear la fecha utilizando la función fechaFormatoCHAT
        this.detalleSeleccionado.diasTranscurridos = this.fechaFormatoCHAT(nuevaFecha);

        
        const lines = [
          `Predicción:`,
          `Fecha aproximada: ${this.detalleSeleccionado.diasTranscurridos}`,
          `Sentido: ${this.detalleSeleccionado.sentido}`,
          `Votación: ${this.detalleSeleccionado.votacion}`
        ];

        const mensaje = lines.join('\n');


        // Configurar y mostrar el modal
        this.modalHeadMessage = 'Detalles de la predicción';
        this.modalMessage = mensaje;
        this.showModal = true;
      },

      
      async getFormatos() {
        try {
           fr
            .collection("formatosGeneral")
            .where("tipoFormato", "in", [4,5,6,8,9,10,11,12,13])
            .where("usuarioRegistro", "==", auth.currentUser.uid)
            .onSnapshot((querySnapshot) => {
              if (!querySnapshot.empty) {
                let docs = [];
                querySnapshot.forEach((doc) => {
                  const fechaFormat = fn.fechaFormato(doc.data().fechaRegistro);
                  const { usuarioInfo } = doc.data();
                  const { estado } = usuarioInfo;
                  docs.push({ 
                    uid: doc.id,
                    ...usuarioInfo,
                    ...doc.data(),
                    estado: estado.estado,
                    fecha_registro: fechaFormat,
                    formato: doc.data().nombreFormato,
                    estatus: doc.data().estatus,
                    costo: doc.data().costo,
                    urlVocher: doc.data().urlVocher,
                    fechaDescarga: doc.data().fechaDescarga
                  });
                });
                this.elementos1 = docs;
              } else {
                console.warn("Formatos free empty");
              }
              
              fr
                .collection("formatosGeneral")
                .where("tipoFormato", "in", [14,15,16])
                .where("usuarioRegistro", "==", auth.currentUser.uid)
                .onSnapshot((querySnapshot) => {
                  if (!querySnapshot.empty) {
                    let docs = [];
                    querySnapshot.forEach((doc) => {
                      const fechaFormat = fn.fechaFormato(doc.data().fechaRegistro);
                      const { usuarioInfo } = doc.data();
                      const { estado } = usuarioInfo;
                      docs.push({ 
                        uid: doc.id,
                        ...usuarioInfo,
                        ...doc.data(),
                        estado: estado.estado,
                        fecha_registro: fechaFormat,
                        formato: doc.data().nombreFormato,
                        estatus: doc.data().estatus,
                        costo: doc.data().costo,
                        urlVocher: doc.data().urlVocher,
                        fechaDescarga: doc.data().fechaDescarga
                      });
                    });
                    this.elementos2 = docs;
                    console.log("ELEMENTOS2: ", this.elementos2);
                    this.elementos = this.elementos1.concat(this.elementos2);
                  } else {
                    console.warn("Formatos free2 empty");
                  }
                });
                this.elementos = this.elementos1.concat(this.elementos2);
                console.log("ELEMENTOS1: ", this.elementos1);
                
            });
        } catch (error) {
          console.error(error);
        }
        
        
      },
      validarEstatus(formato) {
        console.log(formato);
        switch (formato.estatus) {
          case 1:
            return "En espera de pago";
          case 2:
            return "Validando pago";
          case 3:
            return "Listo para descarga";
          case 4:
            return "Formato descargado el "+ fn.fechaFormato(formato.fechaDescarga);
        }
      },
      verModalVoucher(id) {
        console.log(id);
        this.setVoucherBand({ band: true, id });
        window.$("#subirVoucherModal").modal("show");
      },
    },
  };
  </script>
  <style scoped>
  .btnAccion {
    padding: 3px;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    background: #1b1b37;
    color: white;
    margin: 2px;
  }
  .badge {
    font-size: 100%;
    background: #c1c1c1;
  }
  .modal-body {
    display: block !important;
  }
  
  .btnCancel {
    padding: 9px;
    font-size: 14px;
    border: none;
    border-radius: 18px;
    margin: 2px;
  }
  </style>