<template>
  <div>
    <p class="h4">
      <i class="fas fa-file-invoice-dollar mr-2"></i>Formatos de paga generados
    </p>
    <div class="table-responsive">
      <table class="table table-striped">
        <caption>
          Formatos de paga que han sido generados
        </caption>
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Formato</th>
            <th scope="col">Fecha</th>
            <th scope="col">Costo</th>
            <th scope="col">Nombre</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Estatus</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(f, i) in elementos" :key="i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ f.formato }}</td>
            <td>{{ f.fecha_registro }}</td>
            <td>${{ f.costo }}</td>
            <td>{{ f.nombre }}</td>
            <td>{{ f.telefono }}</td>
            <td>
              <span class="badge">{{ validarEstatus(f) }}</span>
            </td>
            <th>
              <button
                v-if="f.estatus == 1"
                type="button"
                class="btnAccion"
                @click="verModalVoucher(f.uid)"
              >
                Subir voucher
              </button>
              <button
                v-if="f.estatus == 2"
                type="button"
                class="btnAccion btnVerVoucher"
                @click="verVoucher(f.uid)"
              >
                Ver voucher
              </button>
              <button
                v-if="f.estatus == 3"
                type="button"
                class="btnAccion btnVerVoucher"
                @click="generarPDF(f)"
              >
                Generar PDF
              </button>
            </th>
          </tr>
          <!--  <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { auth, fr } from "../../firebase";
import fn from "../../libs/index";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "FormatosGenerados",
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.getFormatos();
      }
    });
  },
  beforeDestroy() {
    
  },
  data() {
    return {
      voucherSize: 0,
      cargandoVoucher: false,
      avance: 0,
      voucherIMG: "",

      elementos1: [],
      elementos2: [],

      elementos: [],
      unsuscribe: null,
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/",
    };
  },
  computed: {
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations({
      setVoucherBand: "generals/setVoucherBand",
      setInfoVoucherVisualizacion: "generals/setInfoVoucherVisualizacion",
    }),
    ...mapActions({
      PDF: "contenido/generarPDF",
    }),
    verVoucher(uid) {
      window.$("#vocherPreview").modal("show");
      const [consulta] = this.elementos.filter((c) => c.uid == uid);
      this.setInfoVoucherVisualizacion({
        fecha: consulta.fecha_registro,
        url: consulta.urlVocher,
      });
    },
    async getFormatos() {
      try {
         fr
          .collection("formatosGeneral")
          .where("tipoFormato", "in", [4,5,6,8,9,10,11,12,13])
          .where("usuarioRegistro", "==", auth.currentUser.uid)
          .onSnapshot((querySnapshot) => {
            if (!querySnapshot.empty) {
              let docs = [];
              querySnapshot.forEach((doc) => {
                const fechaFormat = fn.fechaFormato(doc.data().fechaRegistro);
                const { usuarioInfo } = doc.data();
                const { estado } = usuarioInfo;
                docs.push({ 
                  uid: doc.id,
                  ...usuarioInfo,
                  ...doc.data(),
                  estado: estado.estado,
                  fecha_registro: fechaFormat,
                  formato: doc.data().nombreFormato,
                  estatus: doc.data().estatus,
                  costo: doc.data().costo,
                  urlVocher: doc.data().urlVocher,
                  fechaDescarga: doc.data().fechaDescarga
                });
              });
              this.elementos1 = docs;
            } else {
              console.warn("Formatos free empty");
            }
            
            fr
              .collection("formatosGeneral")
              .where("tipoFormato", "in", [14,15,16])
              .where("usuarioRegistro", "==", auth.currentUser.uid)
              .onSnapshot((querySnapshot) => {
                if (!querySnapshot.empty) {
                  let docs = [];
                  querySnapshot.forEach((doc) => {
                    const fechaFormat = fn.fechaFormato(doc.data().fechaRegistro);
                    const { usuarioInfo } = doc.data();
                    const { estado } = usuarioInfo;
                    docs.push({ 
                      uid: doc.id,
                      ...usuarioInfo,
                      ...doc.data(),
                      estado: estado.estado,
                      fecha_registro: fechaFormat,
                      formato: doc.data().nombreFormato,
                      estatus: doc.data().estatus,
                      costo: doc.data().costo,
                      urlVocher: doc.data().urlVocher,
                      fechaDescarga: doc.data().fechaDescarga
                    });
                  });
                  this.elementos2 = docs;
                  console.log("ELEMENTOS2: ", this.elementos2);
                  this.elementos = this.elementos1.concat(this.elementos2);
                } else {
                  console.warn("Formatos free2 empty");
                }
              });
              this.elementos = this.elementos1.concat(this.elementos2);
              console.log("ELEMENTOS1: ", this.elementos1);
              
          });
      } catch (error) {
        console.error(error);
      }
      
      
    },
    validarEstatus(formato) {
      console.log(formato);
      switch (formato.estatus) {
        case 1:
          return "En espera de pago";
        case 2:
          return "Validando pago";
        case 3:
          return "Listo para descarga";
        case 4:
          return "Formato descargado el "+ fn.fechaFormato(formato.fechaDescarga);
      }
    },
    verModalVoucher(id) {
      console.log(id);
      this.setVoucherBand({ band: true, id });
      window.$("#subirVoucherModal").modal("show");
    },
    async generarPDF(formato) {
      console.log(formato);
      try {
        this.$swal({
          icon: "info",
          title: "Generando formato..",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: true,
          didOpen: () => this.$swal.showLoading(),
        });

        const data = await fr.collection("formatosData").doc(formato.uid).get();
        if (data.exists) {
          const documento = data.data();
          console.log(documento);
          let ruta = "";
          let nombre = "";
          switch (formato.tipoFormato) {
            case 4:
              ruta = "generarApelacion";
              nombre = "apelacion.pdf";
              break;
            case 5:
              ruta = "generarJDCAgentesMuniciapales";
              nombre = "agentesMunicipales.pdf";
              break;
            case 6:
              ruta = "generarJRCJucioRevisionConstitucional";
              nombre = "juicio-revision-consitucional-electoral.pdf"
              break;
            case 8:
              ruta = "generarRecursoInconformidad";
              nombre = "Recurso-Inconformidad.pdf"
              break;
            case 9:
              ruta = "generarJuicioInconformidad";
              nombre = "Juicio-Inconformidad.pdf"
              break;
            case 10:
              ruta = "generarTercerInteresado";
              nombre = "tercer-interesado.pdf"
              break;
            case 11:
              ruta = "generarRecursoREP";
              nombre = "recuerso-REP.pdf"
              break;
            case 12:
              ruta = "generarJdcAF";
              nombre = "JDC-AccionesAfirmativas.pdf"
              break;   
            case 13:
              ruta = "generarRecursoRAP";
              nombre = "recuerso-RAP.pdf"
              break; 
            case 14:
              ruta = "generarRecursoREC";
              nombre = "recurso-de-REC.pdf"
              break; 
            case 15:
              ruta = "generarRecursoRAP2";
              nombre = "recurso-apelacion-emplazamiento.pdf"
              break;
            case 16:
              ruta = "generarRecursoJDC2";
              nombre = "juicio-JDC.pdf"
              break;
          }

          await this.PDF({
            url: this.url + ruta,
            nombreFormato: nombre,
            data: documento,
          });

          await fr.collection("formatosGeneral").doc(formato.uid).update({
            estatus: 4,
            fechaDescarga: Date.now(),
          });

          this.$swal.close();
        }
      } catch (error) {
        console.warn(error);
        this.$swal({
          icon: "error",
          title: "Revisa tu conexión a internet e intenta de nuevo",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
        });
      }
    },
  },
};
</script>
<style scoped>
.btnAccion {
  padding: 3px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  background: #1b1b37;
  color: white;
  margin: 2px;
}
.badge {
  font-size: 100%;
  background: #c1c1c1;
}
.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}
</style>