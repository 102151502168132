<template>
  <div>
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <PerfilBreadcrumb :titulo="titulo" :descripcion="descripcion" />
      <section id="service" class="section service-area mt-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-12 mb-5">
              <div class="card">
                <div class="card-header">
                  <i class="far fa-user-circle mr-1"></i>Datos personales
                </div>
                <div class="card-body">
                  <p class="card-text">
                    <i class="fas fa-user-alt mr-2"></i>
                    {{ usuarioLog.nombre }} {{ usuarioLog.apellidos }}
                  </p>
                  <p class="card-text">
                    <i class="fas fa-at mr-2"></i>
                    {{ usuarioLog.correo }}
                  </p>
                  <p class="card-text mb-2">
                    <i class="fas fa-phone mr-2"></i>
                    {{ usuarioLog.telefono ? usuarioLog.telefono : "-" }}
                  </p>
                  <div>
                    <div class="input-group mb-3">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Actualiza tu télefono"
                        aria-label="Recipient's username"
                        aria-describedby="Telefono de la persona"
                        v-model="telefono"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          @click="actualizarTelefono"
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <p class="card-text">
              </p> -->
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <i class="fas fa-certificate mr-1"></i>Premium+
                </div>
                <div class="card-body">
                  <p class="card-text">
                    Tipo de suscripción: {{ tipoSuscripcion ? tipoSuscripcion : 'No tiene' }}
                  </p>
                  <p class="card-text">
                    Fecha de expiración: {{ fechaExpiracion ? fechaExpiracion : '--/--/----' }}
                  </p>
                  <p class="card-text">
                    Estado de suscripción: {{ estaSuscrito ? 'Activa' : 'No suscrito' }}
                  </p>
                  <div class="mb-3 d-flex justify-content-center">
                    <button class="btn btn-primary" :disabled="estaSuscrito" @click="comprarSuscripcion">
                      {{ estaSuscrito ? 'Suscrito' : 'Comprar Suscripción' }}
                    </button>
                  </div>
                </div>
              </div>
              


              <div class="card mt-4" v-if="suscribeData!=null">
                <div class="card-header">
                  <i class="fas fa-wallet mr-1"></i> Solicitudes <b>SUSCRIPCIÓN {{suscribeData.tipo == 1 ? "MENSUAL" : "SEMANAL"}}  </b>
                </div>
                <div class="card-body">
                  <p class="card-text">
                    <i class="fas fa-award mr-2"></i>
                    Suscripcion <b style="color:#a8329d"> {{suscribeData.tipo == 1 ? "Mensual" : "Semanal"}} </b> a ElectoralTech
                  </p>
                  <div>
                    <div class="my-3">
                      <div class="card-text row mx-1" >
                        <p>Estado: <b style="color:#a8329d"> {{suscribeData.estado == 1 ? "Activo" : (suscribeData.estado == 0 ? "Pendiente De Pago" : "Pago En Revision")}} </b> </p>
                      </div>
                      <div class="card-text mt-1 row mx-1" v-if="suscribeData.estado == 1">
                        <p>Fecha De Termino: <b>{{suscribeData.FechaFinPrime}} </b> </p>
                      </div>
                    </div>
                    <div v-if="suscribeData.estado==0">
                      <div>
                        <button
                          class="btn btn-outline-secondary mx-2"
                          type="button"
                          @click="DepositarSuscripcion()"
                        >
                          Depositar
                        </button>
                        
                        <button
                          class="btn paypal-button mx-2 float-right"
                          type="button"
                          @click="pagarConPaypal()"
                        >
                          Pagar Con <b style="color:#003087">Pay</b><b style="color:#009cde">Pal</b>
                        </button>
                        
                      </div>
                    </div>
                  </div>
                  <!-- <p class="card-text">

              </p> -->
                </div>
              </div>
            </div>

            
            <div class="col-lg-9 col-md-8 col-12">
              <PrediccionesGeneradas/>
              <FormatosGenerados />
              <p class="h4">
                <i class="fas fa-info-circle mr-2"></i><span>Solicitudes</span>
              </p>
              <div class="table-responsive">
                <table class="table table-striped">
                  <caption>
                    Solicitudes hechas en el marketplace
                  </caption>
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Consulta</th>
                      <th scope="col">Fecha</th>
                      <th scope="col">Contacto</th>
                      <th scope="col">Estatus</th>
                      <th scope="col">Costo</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in misConsultas" :key="d.id">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ d.consulta }}</td>
                      <td>{{ fechaFormato(d.fechaRegistro) }}</td>
                      <td>{{ d.numero ? d.numero : "-" }}</td>
                      <td>
                        <span class="badge">{{
                          validarEstatus(d.estatus)
                        }}</span>
                      </td>
                      <td> $ {{d.costo}} </td>
                      <td>
                        <!-- <span class="badge" v-if="d.estatus == 0">
                          Espere a ser contactado
                        </span> -->
                        <div v-if="d.estatus == 1">
                          <button
                            type="button"
                            class="btnAccion"
                            @click="verModalVoucher(d.id)"
                          >
                            Subir voucher
                          </button>
                          <button
                            type="button"
                            class="btnAccion"
                            @click="verDetalle(d.id)"
                          >
                            Ver detalle
                          </button>
                        </div>
                        <div
                          v-if="
                            d.estatus == 2 ||
                            d.estatus == 3 ||
                            d.estatus == 4 ||
                            d.estatus == 5
                          "
                        >
                          <button
                            type="button"
                            class="btnAccion btnVerVoucher"
                            @click="verVoucher(d.id)"
                          >
                            Ver voucher
                          </button>
                          <button
                            type="button"
                            class="btnAccion"
                            @click="verDetalle(d.id)"
                          >
                            Ver detalle
                          </button>
                        </div>
                        <div v-if="d.estatus == 3">
                          <button
                            type="button"
                            class="btnAccion btnAvances"
                            @click="avances(d.id)"
                          >
                            Ver avances
                          </button>
                        </div>
                        <button
                          v-if="esCancelable(d.estatus)"
                          type="button"
                          class="btnAccion btnCancelar"
                          @click="cancelar(d)"
                        >
                          Cancelar
                        </button>
                        <div v-if="d.estatus == 4 || d.estatus == 5">
                          <button
                            type="button"
                            class="btnAccion btnAvances"
                            @click="avances(d.id)"
                          >
                            Ver avances
                          </button>
                        </div>
                        <div v-if="d.estatus == 6 || d.estatus == 9">
                          <button
                            type="button"
                            class="btnAccion btnVerVoucher"
                            @click="verVoucher(d.id)"
                          >
                            Ver voucher
                          </button>
                          <button
                            type="button"
                            class="btnAccion btnAvances"
                            @click="avances(d.id)"
                          >
                            Ver avances
                          </button>
                          <button
                            type="button"
                            class="btnAccion"
                            @click="verDetalle(d.id)"
                          >
                            Ver detalle
                          </button>
                        </div>
                        <div v-if="d.estatus == 10">
                          <button
                            type="button"
                            class="btnAccion btnVerVoucher"
                            @click="verVoucher(d.id)"
                          >
                            Ver voucher
                          </button>
                          <button
                            type="button"
                            class="btnAccion btnAvances"
                            @click="avances(d.id)"
                          >
                            Ver avances
                          </button>
                          <button
                            type="button"
                            class="btnAccion"
                            @click="verDetalle(d.id)"
                          >
                            Ver detalle
                          </button>
                          <button
                            type="button"
                            class="btnAccion"
                            @click="verCancelacion(d)"
                          >
                            Ver cancelación
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr id="timeLineDiv" />
        <div class="container" v-if="timelineBand">
          <div class="row">
            <div class="col-12">
              <TimeLine></TimeLine>
            </div>
          </div>
        </div>
        <hr />
      </section>
      <FooterSection />
    </div>
    <div
      class="modal fade"
      id="modalDetalleTercero"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Detalle del tercerop"
      aria-hidden="true"
      v-if="detalle"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detalle e información de depósito</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="alert alert-primary" role="alert">
                  <b>No de cuenta:</b> 182198213 - <b>Banco:</b> Banamex
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <b>Responsable del caso: </b>
                    {{ detalle.responsable.nombre }}
                  </li>
                  <li class="list-group-item">
                    <b>Descripción:</b> {{ detalle.responsable.descripcion }}
                  </li>
                  <li class="list-group-item">
                    <b>Dirección:</b> {{ detalle.responsable.direccion }}
                  </li>
                  <li class="list-group-item">
                    <b> Contacto:</b> {{ detalle.responsable.telefono }}
                  </li>
                  <li class="list-group-item">
                    <b>Costo:</b> ${{ detalle.costo }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div ref="paypal"></div>
    
    <ModalVoucher></ModalVoucher>
    <ModalCancel :estatus="estatusActual" :id="consultaID"></ModalCancel>
    <ModalSolicitudCancel :consulta="consulta"></ModalSolicitudCancel>
    <ModalPagoVoucher></ModalPagoVoucher>
    <ModalPagoPrimer></ModalPagoPrimer>
    <ModalVoucherChatET></ModalVoucherChatET>
    <ModalPaypal :details="detallesCompra" :band="isSelectedPaypal" :datosFCarga="datosFacturacion"></ModalPaypal>
  </div>
</template>

<script>
import ScrollupSection from "../components/Scrollup/Scrollup";
import HeaderSection from "../components/Header/HeaderOne";
import PerfilBreadcrumb from "../components/Breadcrumb/PerfilBreadcrumb";
import FooterSection from "../components/Footer/FooterOne";
import { mapMutations, mapState } from "vuex";
import { auth, fr } from "../firebase";
import fn from "../libs/index";
// import { v4 as uuidv4 } from "uuid";
import TimeLine from "../components/Timeline/Timeline";
import ModalCancel from "../components/Modal/ModalCancel/ModalCancel";
import ModalSolicitudCancel from "../components/Modal/ModalCancel/ModalSolicitudCancel";
import FormatosGenerados from "@/components/Pricing/FormatosGenerados";
import ModalPagoVoucher from "../components/Modal/ModalPagoVoucher/ModalPagoVoucher";
import ModalVoucher from "../components/Modal/ModalPagoVoucher/ModalVoucher";
import ModalPagoPrimer from "../components/Modal/ModalPrime/ModalPagoPrime";
import ModalPaypal from "../components/Modal/ModalPaypal/ModalPaypal";
import ModalVoucherChatET from "../components/Modal/ModalPagoVoucher/ModalVoucherChatET";
import PrediccionesGeneradas from "../components/ChatET/PrediccionesGeneradas.vue";


export default {
  name: "Services",
  components: {
    ScrollupSection,
    HeaderSection,
    PerfilBreadcrumb,
    FooterSection,
    TimeLine,
    ModalCancel,
    ModalSolicitudCancel,
    FormatosGenerados,
    PrediccionesGeneradas,
    ModalVoucherChatET,
    ModalPagoVoucher,
    ModalVoucher,
    ModalPagoPrimer,
    ModalPaypal,
  },
  beforeDestroy() {
    if (this.unsuscribe) {
      this.unsuscribe();
    }
  },
  mounted() {
    this.checkSubscriptionStatus();
    this.datosCargados = true;
    this.setInfoFacturacion();
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("entre al mounted");
        this.obtenerConsultas();
        this.getSuscribeInfo();
      }
    });
    
    
  },
  data() {
    return {
      tipoSuscripcion: null, 
      fechaExpiracion: null,
      estaSuscrito: false,
      descripcion: "Bienvenido, aquí podrás ver tus solicitudes, consultas y documentos solicitados",
      misConsultas: [],
      telefono: "",
      consultaID: null,
      detalle: null,
      timelineBand: false,
      unsuscribe: null,
      estatusActual: null,
      consulta: {},
      suscribe: null,

      suscribeData: {
        FechaFinPrime: null,
        tipo: null,
      },

      datosFacturacion: 
          {
              nombre: null,
              apellido: null,
              correoFactura: null,
              codigoPostal: null,
              telefono: null,
              calle: null,
              colonia: null,
              noExterior: null,
              noInterior: null,
              estado: null,
              ciudad: null,
              rfc: null,
          },


      isSelectedPaypal: false,
      detallesCompra:{
        price: 250,
        description: "suscripcion mensual",
        img: "",
      },
           

      loaded: false,
      paidFor: false,
    };
  },
  computed: {
    ...mapState("auth", ["usuarioLog"]),
    ...mapState("generals", ["voucherBand", "idConsultaVoucher"]),
    titulo() {
      return "Hola, " + this.usuarioLog.nombre;
    },
  },
  methods: {
    ...mapMutations({
      setUsuario: "auth/setUsuario",
      setConsulta: "contenido/setConsulta",
      setVoucherBand: "generals/setVoucherBand",
      setInfoVoucherVisualizacion: "generals/setInfoVoucherVisualizacion",
    }),

    wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

    formatTimestamp(timestamp) {
      const date = timestamp.toDate();
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleDateString('es-MX', options);
    },

    async checkSubscriptionStatus() {
      await this.wait(1000);
        try {
          const userId = auth.currentUser.uid;

          const snapshot = await fr.collection('suscriptoresPremiumPlus')
                                    .where('idUsuario', '==', userId)
                                    .where('estado', '==', 'activo')
                                    .get();

          if (!snapshot.empty) {
            console.log("ACTIVO")
            const doc = snapshot.docs[0];
            this.tipoSuscripcion = doc.data().tipoSuscripcion;
            this.fechaExpiracion = this.formatTimestamp(doc.data().fechaVencimiento);
            this.estaSuscrito = true;
          } else {
            console.log("NO SUSC")
            this.estaSuscrito = false;
          }
        } catch (error) {
          console.error('Error en checkSubscriptionStatus:', error);
          throw error; // Lanza el error para que sea capturado por el método alert()
        }
      },

    comprarSuscripcion() {
      // Método para comprar o renovar la suscripción
      if (this.estaSuscrito) {
        console.log('Ya estás suscrito');
        // Aquí podrías mostrar un mensaje o manejar la lógica para indicar que ya está suscrito
      } else {
        console.log('Comprar o renovar suscripción');
        window.$('#pagarPremiumPlusModal').modal('show');
      }
    },

    DepositarSuscripcion(){
      window.$("#pagarPrimeModal").modal("show")
    },

    async setInfoFacturacion(){
      const temp = JSON.parse(sessionStorage.getItem("usuario"));
      this.datosFacturacion = temp.datosFacturacion;
      console.log("LOS DATOS DE FACTURACION CARGDOS: ", this.datosFacturacion)
    },

    setLoaded: function(){
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data,actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.detallesCompra.description,
                  amount: {
                    currency_code: "MXN",
                    value: this.detallesCompra.price
                  }
                }
              ]
            })
          }
        })
        .render(this.$refs.paypal);
    },

    pagarConPaypal(){
      this.isSelectedPaypal = true;
      window.$("#ModalPaypal").modal({
        backdrop: 'static',
        keyboard: false,
      });
    },
    
    async actualizarTelefono() {
      // this.telefono
      if (this.telefono != "" && this.telefono.length == 10) {
        try {
          const data = { telefono: this.telefono };
          await fr
            .collection("usuarios")
            .doc(auth.currentUser.uid)
            .update(data);
          this.sweetAlert("success", "Exito", "Teléfono actualizado");
          this.setUsuario(data);
          this.telefono = "";
        } catch (error) {
          this.sweetAlert(
            "error",
            "Error",
            "Revisa tu conexión a internet e intenta de nuevo más tarde"
          );
        }
      } else {
        this.sweetAlert(
          "error",
          "El teléfono debe ir a 10 dígitos",
          "Escribe el número teléfonico correctamente"
        );
      }
    },

    async getSuscribeInfo(){
      try{
        const userId = auth.currentUser.uid; 
        const userData = await fr
        .collection("suscripciones")
        .doc(userId)
        .get()
        this.suscribeData = userData.data();
        var date = new Date(this.suscribeData.FechaFinPrime);
        var options = {
                year: 'numeric', month: 'numeric', day: 'numeric',
            };

        var result = date.toLocaleDateString('en', options);
        this.suscribeData.FechaFinPrime = result
      } catch (error) {
        console.log("Entre al catch");
        this.suscribeData = null;
      }
      try{
        if(this.suscribeData.tipo == 1){
          this.detallesCompra.price = 250;
          this.detallesCompra.description = "Suscripcion Mensual Al Servicio De Informacion"

        }else{
          this.detallesCompra.price = 100;
          this.detallesCompra.description = "Suscripcion Semanal Al Servicio De Informacion"
        }
      }catch(error){
        console.log("Este usuario no tiene informacion de suscripcion");
        this.suscribeData = null;
      }  
    },

    fechaFormato(mil) {
      return fn.fechaFormato(mil);
    },
    obtenerConsultas() {
      // console.log("ejecutandose", auth.currentUser.uid);
      this.unsuscribe = fr
        .collection("consultas")
        .where("usuarioRegistro", "==", auth.currentUser.uid)
        .onSnapshot((snapshot) => {
          if (!snapshot.empty) {
            let temp = [];
            snapshot.forEach((doc) => {
              // console.log(doc.data());
              temp.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.misConsultas = temp;
          } else {
            this.misConsultas = [];
          }
        });
    },
    verModalVoucher(id) {
      this.setVoucherBand({ band: false, id });
      this.timelineBand = false;
      window.$("#subirVoucherModal").modal("show");
    },
    async verDetalle(id) {
      this.timelineBand = false;
      try {
        const [consulta] = this.misConsultas.filter((c) => c.id == id);
        //console.log(consulta.tercero.id);
        const querySnapshot = await fr
          .collection("terceros")
          .doc(consulta.tercero.id)
          .get();
        if (querySnapshot.exists) {
          // console.log("existe");
          this.detalle = {
            responsable: querySnapshot.data(),
            costo: consulta.costo,
          };
          // console.log(consulta);
          // se retrasa el modal ya que el v-if esta validando la data,
          // cuando la data se encuentra hay un delay para que el modal aparezca en el dom
          // y pueda ser encontrado por el selector
          setTimeout(() => window.$("#modalDetalleTercero").modal("show"), 100);
          // window.$("#modalDetalleTercero").modal("show");
        } else {
          // console.log("no existe");
        }
      } catch (error) {
        console.warn(error);
      }
    },
    verVoucher(id) {
      window.$("#vocherPreview").modal("show");
      const [consulta] = this.misConsultas.filter((c) => c.id == id);
      this.setInfoVoucherVisualizacion({
        fecha: this.fechaFormato(consulta.fechaVoucher),
        url: consulta.urlVocher,
      });
      this.timelineBand = false;
    },
    validarEstatus(estatus) {
      switch (estatus) {
        case 0:
          return "Pendiente";
        case 1:
          return "En espera de pago";
        case 2:
          return "Validando pago";
        case 3:
          return "Tramite en proceso";
        case 4:
          return "Tramite finalizado";
        case 5:
          return "Tramite finalizado sin exito";
        case 6:
          //cancelado por el usuario pero hubo pago
          return "Revisando cancelación de servicio";
        case 7:
          // cancelado por el usuario sin pago
          return "Cancelando servicio";
        default:
          return "Cancelado";
        /*  case 8:
          return "Cancelado";
        case 9:
          return "Cancelado";
        case 10:
          return "Cancelado"; */
      }
    },
    esCancelable(estatus) {
      let listadoEstatus = [4, 5, 6, 7, 8, 9, 10];
      return !listadoEstatus.includes(estatus);
    },
    cancelar(consulta) {
      this.estatusActual = consulta.estatus;
      this.consultaID = consulta.id;
      window.$("#CancelModal").modal("show");
      this.timelineBand = false;
    },

    async avances(id) {
      try {
        this.sweetAlertLoading("Cargando avances...");
        const [consulta] = this.misConsultas.filter((c) => c.id == id);
        const snap = await fr.collection("avances").doc(id).get();

        if (snap.exists) {
          const el = document.getElementById("timeLineDiv");
          if (el) {
            //https://stackoverflow.com/questions/40730116/scroll-to-bottom-of-div-with-vue-js
            el.scrollIntoView({ behavior: "smooth" });
          }
          this.setConsulta({
            ...snap.data(),
            ...consulta,
          });
          this.timelineBand = true;
          this.$swal.close();
        } else {
          this.sweetAlert("info", "Info", "Este consultoria no tuvo avances");
          this.timelineBand = false;
        }
      } catch (error) {
        console.warn(error);
        this.sweetAlert("error", "Error", "Error de conexión");
      }
    },

    sweetAlert(tipo, titulo, texto) {
      this.$swal({
        icon: tipo,
        title: titulo,
        text: texto,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    },
    sweetAlertLoading(titulo) {
      this.$swal({
        icon: "info",
        title: titulo,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: true,
        showCancelButton: true,
        didOpen: () => this.$swal.showLoading(),
      });
    },
    verCancelacion(consulta) {
      this.timelineBand = false;
      this.consulta = consulta;
      setTimeout(() => {
        window.$("#ModalSolicitudCancel").modal("show");
        window.$(".carousel").carousel();
      }, 100);
      // ModalSolicitudCancel
    },

    
  },
};
</script>

<style scoped>
.paypal-button{
  background: #ffc439!important;
}

.badge {
  font-size: 100%;
  background: #c1c1c1;
}

.btnAccion {
  padding: 3px;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  background: #1b1b37;
  color: white;
  margin: 2px;
}

/* .btnAccion:hover {
  background: linear-gradient(-45deg, #af52ed 0%, #8830df 100%);
} */

.btnVerVoucher {
  background: #555555;
}

.btnCancelar {
  background: crimson;
  border: 1px solid crimson;
}

.btnAvances {
  background: #3f51b5;
}

.modal-body {
  display: block !important;
}

.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}
</style>