<template>
  <div>
    <div class="row">
      <div class="col-6">
        <p class="h4">
          <i class="fas fa-info-circle mr-2"></i><span>Información general</span>
        </p>
        <p class="h6">
          <b>Responsable: </b> {{ consulta.tercero.text }}
        </p>
        <p class="h6">
          <b>Consulta: </b> {{ consulta.consulta }}
        </p>
        <p class="h6">
          <b>Costo: </b> $ {{ consulta.costo }}
        </p>
        <p class="h6">
          <b>Última actualización: </b> {{ fechaFormato(consulta.ultimaActualizacion) }}
        </p>
        
      </div>
      <div class="col-6">
        <p class="h4">
          <i class="fas fa-info-circle mr-2"></i><span>Fechas</span>
        </p>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><b>Fecha de registro: </b> {{ fechaFormato(consulta.fechaRegistro) }} </li>
          <li class="list-group-item"><b>Fecha de atención: </b> {{ fechaFormato(consulta.fechaEspera) }}</li>
          <li class="list-group-item"><b>Fecha de pago: </b> {{ fechaFormato(consulta.fechaVoucher) }}</li>
          <li class="list-group-item"><b>Fecha de inicio de trámite: </b> {{ fechaFormato(consulta.fechaInicioTramite) }}</li>
        </ul>
      </div>

      <div class="my-4">
        <div class="row">
          <h3>EXPEDIENTE COMPLETO</h3>
        </div>
        <div class="row">
          <div class="md-3 sm-12" v-for="(e, i) in consulta.etapas" :key="i">
            <a class="blog-btn px-4 mx-2" style="cursor: pointer" :href="e.linkPdf" target="_blank">{{e.estatus}}  </a>
          </div>
        </div>
      </div>



    </div>
    <section class="timeline">
      <div class="container">
        
        <div class="timeline-item" v-for="(e, i) in consulta.etapas" :key="i">
          <div class="timeline-img"></div>

          <div :class="`timeline-content ${setSide(i)}`">
            <a class="blog-btn px-5" style="cursor: pointer" :href="e.linkPdf" target="_blank">{{e.estatus}}  </a>
            <div class="date">{{  fechaFormato(e.fecha) }}</div>
            <p>
              {{  e.descripcion }}
            </p>
            <!-- <a class="bnt-more" href="javascript:void(0)">More</a> -->
          </div >
        </div>

        <!-- <div class="timeline-item">
          <div class="timeline-img"></div>

          <div class="timeline-content js--fadeInRight">
            <h2>Title</h2>
            <div class="date">22 JUN 2016</div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime
              ipsa ratione omnis alias cupiditate saepe atque totam aperiam sed
              nulla voluptatem recusandae dolor, nostrum excepturi amet in
              dolores. Alias, ullam.
            </p>
            <a class="bnt-more" href="javascript:void(0)">More</a>
          </div>
        </div> -->
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import fn from '../../libs/index';

export default {
  name: "TimeLine",
  data() {
    return {};
  },
  methods : {
    fechaFormato(mil) {
      return fn.fechaFormato(mil);
    },
    setSide(i){
      return i % 2 == 0 ? 'js--fadeInLeft'  : 's--fadeInRight'
    }
  },
  computed: {
    ...mapState('contenido', ['consulta']),
  }
};
</script>
<style scoped>
/* section {
  padding: 100px 0;
} */

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 400;
  background-color: #eee;
}

h3{
  margin-top: 6px;
}

h1 {
  font-size: 200%;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 400;
}

header {
  background: #3f51b5;
  color: #ffffff;
  padding: 150px 0;
}
header p {
  font-family: "Allura";
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 0;
  font-size: 60px;
  margin-top: -30px;
}

.timeline {
  position: relative;
}
.timeline::before {
  content: "";
  background: #c5cae9;
  width: 5px;
  height: 95%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;
}
.timeline-item:nth-child(even) .timeline-content {
  float: right;
  padding: 40px 30px 10px 30px;
}
.timeline-item:nth-child(even) .timeline-content .date {
  right: auto;
  left: 0;
}
.timeline-item:nth-child(even) .timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  left: -15px;
  border-width: 10px 15px 10px 0;
  border-color: transparent #f5f5f5 transparent transparent;
}
.timeline-item::after {
  content: "";
  display: block;
  clear: both;
}

.timeline-content {
  position: relative;
  width: 45%;
  padding: 10px 30px;
  border-radius: 4px;
  background: #f5f5f5;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
}
.timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  right: -15px;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #f5f5f5;
}

.timeline-img {
  width: 30px;
  height: 30px;
  background: #3f51b5;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  margin-left: -15px;
}

a {
  background: #3f51b5;
  color: #ffffff;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.6);
}
a:hover,
a:active,
a:focus {
  background: #32408f;
  color: #ffffff;
  text-decoration: none;
}

.timeline-card {
  padding: 0 !important;
}
.timeline-card p {
  padding: 0 20px;
}
.timeline-card a {
  margin-left: 20px;
}

/* .timeline-item .timeline-img-header {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
    url("https://picsum.photos/1000/800/?random") center center no-repeat;
  background-size: cover;
}

.timeline-img-header {
  height: 200px;
  position: relative;
  margin-bottom: 20px;
}
.timeline-img-header h2 {
  color: #ffffff;
  position: absolute;
  bottom: 5px;
  left: 20px;
}
 */
blockquote {
  margin-top: 30px;
  color: #757575;
  border-left-color: #3f51b5 !important;
  padding: 0 20px;
}

.date {
  background: #8d35e1;
  display: inline-block;
  color: #ffffff;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .timeline::before {
    left: 50px;
  }
  .timeline .timeline-img {
    left: 50px;
  }
  .timeline .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 70px;
  }
  .timeline .timeline-item:nth-child(even) .timeline-content {
    float: none;
  }
  .timeline .timeline-item:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
  }
}
</style>