<template>
  <div v-if="consulta.id">
    <div
      class="modal fade"
      id="ModalSolicitudCancel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ModalDeCancelacion"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="fas fa-ban"></i> Resumen de cancelación
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <h5>Capturas</h5>
                <div
                  id="carouselExampleIndicators"
                  class="carousel slide"
                  data-ride="carousel"
                >
                  <ol class="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="0"
                      class="active"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    ></li>
                  </ol>
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img
                        class="d-block w-100"
                        :src="consulta.prueba1"
                        alt="Primera prueba de cancelación"
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        class="d-block w-100"
                        :src="consulta.prueba2"
                        alt="Segunda prueba de cancelación"
                      />
                    </div>
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <h5>Consulta</h5>
                <p>
                  {{ consulta.consulta }}
                </p>
              </div>
              <div class="col-12 mb-2">
                <h5>Motivos de cancelación</h5>
                <p>
                  {{ consulta.motivoCancelacion }}
                </p>
              </div>
              <div class="col-12 mb-2">
                <div class="alert alert-secondary" role="alert">
                  <h5 class="alert-heading">Respuesta</h5>
                  <p>
                    {{ consulta.respuestaCancelacion }}
                  </p>
                  <p class="mb-0">
                    Dada las razones antes mencionadas, el reembolso {{ consulta.reembolso ? 'estará disponible en los proximos días' : 'no sera posible' }}
                  </p>
                  <hr />
                  <p> Para más información o soporte comunicate al <i class="fab fa-whatsapp-square"></i>  <b>22-22-22-22-22</b> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalSolicitudCancel",
  props: {
    consulta: Object,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.modal-body {
  display: block !important;
}
.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}
</style>