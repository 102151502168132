<template>
    <div>
      <div
        class="modal fade"
        id="pagarPremiumPlusModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="pagarPremiumPlusModal"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Subir comprobante de pago</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="alert alert-info" role="alert">
                    <strong class="text text-center">Banco:  </strong> <span>Santander</span> <br> 
                    <strong>Numero De Cuenta:  </strong> <span> 65608700242</span> <br>
                    <strong>CLABE:  </strong> <span>014840655087002428</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="alert alert-warning" role="warning">
                    El voucher debe ser una <strong>imagen</strong>, de menos de
                    <strong>2 Mb</strong>
                  </div>
                </div>
              </div>
              <div class="row" v-if="voucherSize > 2000">
                <div class="col-12">
                  <div
                    class="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <strong>Error !! </strong>
                    El voucher debe ser una imagen, de menos de 2 Mb
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="progress"
                    style="height: 3px"
                    v-if="cargandoVoucher"
                  >
                    <div
                      class="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      :aria-valuenow="avance"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: avance + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Selecciona la imagen de tu comprobante de pago</label
                    >
                    <input
                      type="file"
                      accept="image/*"
                      class="form-control"
                      v-on:change="cargarVoucher"
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-if="voucherIMG">
                <div class="col-12">
                  <img
                    :src="voucherIMG"
                    alt="Imagen del Vocher"
                    class="img-thumbnail"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 text-center">
                  <button
                    name="btnMensual"
                    type="button"
                    class="btn btn-primary btn-suscripcion-mensual"
                    :class="{ 'btn-anual': tipoSuscripcion === 'Mensual', 'btn-mensual': tipoSuscripcion === 'Mensual' }"
                    @click="eleccionMensual"
                  >
                  Mensual $180 MXN
                  </button>
                  <button
                    name="btnAnual"
                    type="button"
                    class="btn btn-primary btn-suscripcion-anual"
                    :class="{ 'btn-anual': tipoSuscripcion === 'Anual', 'btn-mensual': tipoSuscripcion === 'Anual' }"
                    @click="eleccionAnuall"
                  >
                  Anual $1800 MXN
                  </button>
                </div>
              </div>
            <div class="modal-footer">
              <button type="button" class="btnCancel" data-dismiss="modal">
                Cancelar
              </button>
              <button
                type="button"
                v-if="voucherSize < 2000"
                class="btn btn-primary"
                @click="subirVoucher"
              >
                Subir Voucher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapState, mapMutations } from "vuex";
  import { v4 as uuidv4 } from "uuid";
  import { auth, fr, storage } from "../../../firebase";
  
  export default {
    name: "ModalVoucherChatET",
    data() {
      return {
        voucherSize: 0,
        cargandoVoucher: false,
        avance: 0,
        voucherIMG: "",
        extensionesValidas: ["png", "jpg", "jpeg"],
        vocherEXT: "",
        tipoSuscripcion: "Mensual"
      };
    },
    computed: {
      ...mapState("generals", ["voucherBand", "idConsultaVoucher"]),
      ...mapState("auth", ["usuarioLog"]),
    },
    methods: {
      ...mapMutations({
        setVoucherBand: "generals/setVoucherBand",
      }),
      cargarVoucher(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        this.vocherEXT = files[0].type.split("/")[1];
        if (this.extensionesValidas.includes(this.vocherEXT)) {
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          this.voucherSize = files[0].size / 1000;
          reader.onload = (re) => {
            this.voucherIMG = re.target.result;
          };
        } else {
          this.sweetAlert(
            "error",
            "Formato de imagen incorrecto",
            "Los formatos validos son: .png, .jpg, .jpeg"
          );
        }
      },

      eleccionMensual() {
        this.tipoSuscripcion = "Mensual";
        console.log(this.tipoSuscripcion)
      },

      eleccionAnual() {
        this.tipoSuscripcion = "Anual";
        console.log(this.tipoSuscripcion)
      },
  
      async subirVoucher() {
        // console.log(id);
        if (this.voucherIMG != null && this.voucherIMG != "") {
          this.cargandoVoucher = true;
          this.avance = 20;
          try {
            const GUID = uuidv4();
            const imgContentType = "image/" + this.vocherEXT;
            const imgPromise = this.guardarFile(
              this.voucherIMG,
              GUID,
              imgContentType,
              this.vocherEXT
            );
            const voucherRef = await imgPromise;
            this.avance = 40;
            const urlVocher = await voucherRef.ref.getDownloadURL();
            this.avance = 80;
            let coleccion = "solicitudesPremiumPlus";
            // const updateSuscripcion = await fr
            // .collection(coleccion).doc(auth.currentUser.uid).update({
            //   estado: "pendiente",
            //   voucherURL: urlVocher,
            //   idUsuario: auth.currentUser.uid,
            //   fechaSolicitud: Date.now(),
            //   estado: 2,
            // });
            const userId = auth.currentUser.uid
            const lastDoc = await fr.collection(coleccion)
                                    .orderBy('numConsulta', 'desc')
                                    .limit(1)
                                    .get();
            
            let nuevoNumConsulta = 1; 
            if (!lastDoc.empty) {
            const ultimoDocumento = lastDoc.docs[0].data();
            nuevoNumConsulta = parseInt(ultimoDocumento.numConsulta) + 1;
            }

            const nuevaSolicitud = {
            estado: "pendiente",
            voucherURL: urlVocher,
            idUsuario: userId,
            fechaSolicitud: new Date(), 
            numConsulta: nuevoNumConsulta.toString(), 
            tipoSuscripcion: this.tipoSuscripcion, 
            usuarioCorreo: this.usuarioLog.correo, 
            usuarioNombre: this.usuarioLog.nombre, 
            };

            await fr.collection(coleccion).add(nuevaSolicitud);

            console.log(urlVocher);
            this.avance = 100;
            this.voucherIMG = "";
            this.imgContentType = "";
            this.vocherEXT = "";
  
            window.$("#pagarPremiumPlusModal").modal("hide");
            window.location.reload();
            // this.obtenerConsultas();
          } catch (error) {
            window.$("#pagarPremiumPlusModal").modal("hide");
            this.sweetAlert(
              "error",
              "Error",
              "Revisa que tu voucher sea un archivo valido, y tu conexión a internet e intenta de nuevo"
            );
            console.warn(error);
          } finally {
            this.cargandoVoucher = false;
            this.avance = 0;
          }
        } else {
          this.sweetAlert("error", "Error", "Selecciona un comprobante de pago");
        }
      },
      async guardarFile(file, fileName, contentType, extension) {
        this.timelineBand = false;
        const storageRef = storage.ref();
  
        const metaData = {
          cacheControl: "public,max-age=300",
          contentType,
        };
        let path = `vouchersPremiumPlus/${auth.currentUser.uid}/${fileName}.${extension}`;
        return await storageRef.child(path).putString(file, "data_url", metaData);
      },
      sweetAlert(tipo, titulo, texto) {
        this.$swal({
          icon: tipo,
          title: titulo,
          text: texto,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
      },
      sweetAlertLoading(titulo) {
        this.$swal({
          icon: "info",
          title: titulo,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: true,
          showCancelButton: true,
          didOpen: () => this.$swal.showLoading(),
        });
      },
    },
  };
  </script>
  <style >
  .modal-body {
    display: block !important;
  }
  
  .btnCancel {
    padding: 9px;
    font-size: 14px;
    border: none;
    border-radius: 18px;
    margin: 2px;
  }

  .btn-suscripcion {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 18px;
    margin: 2px;
  }
  
  .btn-anual {
    background-color: #007bff;
    color: white;
  }
  
  .btn-mensual {
    background-color: #6c757d;
    color: white;
  }
  </style>