<template>
  <div>
    <div
      class="modal fade"
      id="CancelModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ModalDeCancelacion"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <i class="fas fa-ban"></i> Cancelación de un servicio
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="estatus == 2 || estatus == 3">
              <div class="col-12">
                <div class="alert alert-warning" role="alert">
                  El servicio de consultoria
                  <b> ha sido pagado, o esta en revisión de pago</b> .
                  Proporcionanos la siguiente información para poder ayudarte a
                  cancelar y deacuerdo tu caso y razones realizar una devolción
                  parcial. <b>Para más información</b> comunicate al
                  <i class="fab fa-whatsapp"></i> 22-22-22-22-22
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">
                    <i class="fas fa-exclamation-triangle"></i> Describe las
                    razones por las que quieres cancelar el servicio.
                  </label>
                  <textarea
                    class="form-control"
                    v-model.trim="descripcion"
                    rows="3"
                    placeholder="Describe detalladamente tus razones"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row" v-if="estatus == 2 || estatus == 3">
              <div class="col-6">
                <img
                  v-if="img1 != ''"
                  :src="img1"
                  alt="Imagen de comprobación de mal servicio"
                />
                <div class="form-group">
                  <label for="ImagenCancelacion1">
                    <i class="fas fa-exclamation"></i> Adjunta una imagen de
                    prueba.
                  </label>
                  <input
                    type="file"
                    id="ImagenCancelacion1"
                    v-on:change="imgCancelacion1"
                    accept="image/*"
                    class="form-control"
                  />
                  <small>Imagen que muestre el motivo de cancelación </small>
                </div>
              </div>
              <div class="col-6">
                <img
                  v-if="img2 != ''"
                  :src="img2"
                  alt="Imagen de comprobación de mal servicio"
                />
                <div class="form-group">
                  <label for="ImagenCancelacion2">
                    <i class="fas fa-exclamation"></i> Adjunta una imagen de
                    prueba.
                  </label>
                  <input
                    type="file"
                    id="ImagenCancelacion2"
                    accept="image/*"
                    v-on:change="imgCancelacion2"
                    class="form-control"
                  />
                  <small>Imagen que muestre el motivo de cancelación </small>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btnCancel" data-dismiss="modal">
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="cancelarConsulta"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fr, storage, auth } from "../../../firebase";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "ModalCancel",
  props: {
    estatus: Number,
    id: String,
  },
  mounted() {
    console.warn(this.estatus, this.id);
  },
  data() {
    return {
      descripcion: "",
      extensionesValidas: ["png", "jpg", "jpeg"],
      img1: "",
      img1Ext: "",
      img1Size: 0,
      img2: "",
      img2Ext: "",
      img2Size: 0,
    };
  },
  methods: {
    imgCancelacion1(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.img1Ext = files[0].type.split("/")[1];
      if (this.extensionesValidas.includes(this.img1Ext)) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        this.img1Size = files[0].size / 1000;
        reader.onload = (re) => {
          this.img1 = re.target.result;
        };
      } else {
        this.sweetAlert(
          "error",
          "Formato de imagen incorrecto",
          "Los formatos validos son: .png, .jpg, .jpeg"
        );
      }
    },
    imgCancelacion2(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.img2Ext = files[0].type.split("/")[1];
      if (this.extensionesValidas.includes(this.img2Ext)) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        this.img2Size = files[0].size / 1000;
        reader.onload = (re) => {
          this.img2 = re.target.result;
        };
      } else {
        this.sweetAlert(
          "error",
          "Formato de imagen incorrecto",
          "Los formatos validos son: .png, .jpg, .jpeg"
        );
      }
    },
    async cancelarConsulta() {
      console.warn(this.estatus, this.id, this.descripcion);
      if (
        this.estatus != null &&
        this.id != "" &&
        this.descripcion != "" &&
        this.img1 != "" &&
        this.img2 != ""
      ) {
        try {
          let tempEstatus = null;
          if (this.estatus == 2 || this.estatus == 3) {
            console.warn("probablmente ya pagado");
            tempEstatus = 6;
          } else {
            tempEstatus = 7;
            console.warn("sn pagar aun");
          }

          this.sweetAlertLoading("Cancelando...");
          const GUID = uuidv4();
          const GUID2 = uuidv4();

          const img1ContentType = "image/" + this.img1Ext;
          const img2ContentType = "image/" + this.img2Ext;

          const imgPromise1 = this.guardarFile(
            this.img1,
            GUID,
            img1ContentType,
            this.img1Ext
          );

          const imgPromise2 = this.guardarFile(
            this.img2,
            GUID2,
            img2ContentType,
            this.img2Ext
          );

          const img1Reference = await imgPromise1;
          const img2Reference = await imgPromise2;

          const img1URL = await img1Reference.ref.getDownloadURL();
          const img2URL = await img2Reference.ref.getDownloadURL();

          await fr.collection("consultas").doc(this.id).update({
            fechaCancelacion: Date.now(),
            motivoCancelacion: this.descripcion,
            prueba1: img1URL,
            prueba2: img2URL,
            estatus: tempEstatus,
          });

          window.$("#CancelModal").modal("hide");
          this.descripcion = "";
          this.img1 = "";
          this.img1Ext = "";
          this.img1Size = 0;
          this.img2 = "";
          this.img2Ext = "";
          this.img2Size = "";
          this.sweetAlert(
            "success",
            "Exito",
            "El tramite fue cancelado, si necesitas más información comunicate, con la información de soporte"
          );
        } catch (error) {
          console.warn(error);
          this.sweetAlert(
            "error",
            "Error",
            "Revisa tu conexión a internet e intenta de nuevo"
          );
        }
      } else {
        this.sweetAlert(
          "error",
          "Error",
          "Completa todos los campos por favor"
        );
      }
    },
    async guardarFile(file, fileName, contentType, extension) {
      const storageRef = storage.ref();

      const metaData = {
        cacheControl: "public,max-age=300",
        contentType,
      };
      const path = `cancelaciones/${auth.currentUser.uid}/${fileName}.${extension}`;
      return await storageRef.child(path).putString(file, "data_url", metaData);
    },
    sweetAlert(tipo, titulo, texto) {
      this.$swal({
        icon: tipo,
        title: titulo,
        text: texto,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    },
    sweetAlertLoading(titulo) {
      this.$swal({
        icon: "info",
        title: titulo,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        timerProgressBar: true,
        didOpen: () => this.$swal.showLoading(),
      });
    },
  },
};
</script>
<style scoped>
.modal-body {
  display: block !important;
}
.btnCancel {
  padding: 9px;
  font-size: 14px;
  border: none;
  border-radius: 18px;
  margin: 2px;
}
</style>