<template>
    <div 
        class="modal fade"
        id="ModalPaypal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ModalPaypal"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title textoTitulo">PAYPAL CHECKOUT</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="justify-content-center mx-0">
                    <div class="row px-5 pb-4">
                        <div class="col-12 col-md-8">
                            <div class="row">   
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            DATOS DE LA CUENTA
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title">Propietario:</h5> 
                                            <h5 style="color:blue" > {{ userDatail.correo }}</h5>
                                            <p class="card-text">La suscripción a servicio de información de ElectroalTech será abonada a esta cuenta.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 my-4">
                                    <div class="card">
                                        <div class="card-header">
                                            <a  class="blog-btn" style="color:#5a0394; cursor: pointer" @click="selectFactura=!selectFactura">DATOS FACTURACION</a> <span v-if="datosLlenados==true" class="float-right">Datos Guardados</span>
                                        </div>
                                        <div v-if="selectFactura==true" class="card-body">
                                            <form class="form-row">
                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">Nombres*</label>
                                                    <input type="text" v-model="datosFacturacion.nombre" class="form-control form-settings" >
                                                </div>
                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">Apellidos*</label>
                                                    <input type="text" v-model="datosFacturacion.apellido" class="form-control form-settings" >
                                                </div>
                                                <div class="col-md-12 md-12 form-group float-label-control">
                                                    <label for="">Correo De Facturacion*</label>
                                                    <input type="mail" v-model="datosFacturacion.correoFactura" class="form-control form-settings">
                                                </div>
                                                <div class="col-md-12 md-12 form-group float-label-control">
                                                    <label for="">RFC</label>
                                                    <input type="text" v-model="datosFacturacion.rfc" class="form-control form-settings">
                                                </div>
                                                <div class="my-5"></div>

                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">Codigo Postal</label>
                                                    <input type="number" v-model="datosFacturacion.codigoPostal" class="form-control form-settings">
                                                </div>

                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">Telefono</label>
                                                    <input type="number" v-model="datosFacturacion.telefono" class="form-control form-settings">
                                                </div>
                                                <div class="col-md-12 md-12 form-group float-label-control">
                                                    <label for="">Calle</label>
                                                    <input type="text" v-model="datosFacturacion.calle" class="form-control form-settings">
                                                </div>
                                                <div class="col-md-12 md-12 form-group float-label-control">
                                                    <label for="">Colonia</label>
                                                    <input type="text" v-model="datosFacturacion.colonia" class="form-control form-settings">
                                                </div>
                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">No. Exterior</label>
                                                    <input type="number" v-model="datosFacturacion.noExterior" class="form-control form-settings">
                                                </div>

                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">No. Interior</label>
                                                    <input type="number" v-model="datosFacturacion.noInterior" class="form-control form-settings">
                                                </div>
                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">Estado</label>
                                                    <input type="text" v-model="datosFacturacion.estado" class="form-control form-settings" >
                                                </div>

                                                <div class="col-md-6 md-6 form-group float-label-control">
                                                    <label for="">Ciudad</label>
                                                    <input type="text" v-model="datosFacturacion.ciudad" class="form-control form-settings">
                                                </div>
                                            </form>
                                            <button class="btn float-right" @click="GuardarDatosFactura()">Confirmar</button>
                                            <button class="btn float-left clear-btn">Limpiar</button>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                        <div class="col-12  col-md-4">
                            <div class="card">
                                <div class="card-header">
                                    PAGAR
                                </div>
                                <div id="pagarBox" :v-bind="loaded" class="card-body">
                                    <div ref="paypal"></div>
                                    <small>*Llena los datos de facturacion para continuar</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapMutations,mapActions } from "vuex";
import { auth , fr } from "../../../firebase";
export default {
    name: "ModalPaypal",

    props:["details","band","datosFCarga"],

    data(){
        return{
             loaded: false,
             paidFor: false,
             userDatail: [],
             selectFactura: false,

             datosLlenados: false,
             datosFacturacion: 
                {
                    nombre: null,
                    apellido: null,
                    correoFactura: null,
                    codigoPostal: null,
                    telefono: null,
                    calle: null,
                    colonia: null,
                    noExterior: null,
                    noInterior: null,
                    estado: null,
                    ciudad: null,
                    rfc: null,
                },
        }
    },
    mounted(){
        

    },
    methods:{

        ...mapMutations({
        setUsuario: "auth/setUsuario",
        }),
        ...mapActions({
        getMailTemplateUser: "generals/getMailTemplateUser",
        }),

        closeModal(){
            this.selectFactura = false;
            this.$emit('band', false); 
        },

        LimpiarDatosFactura(){
            this.datosFacturacion =  
                {
                    nombre: null,
                    apellido: null,
                    correoFactura: null,
                    codigoPostal: null,
                    telefono: null,
                    calle: null,
                    colonia: null,
                    noExterior: null,
                    noInterior: null,
                    estado: null,
                    ciudad: null,
                    rfc: null,
                }
            ;
        },

        async GuardarDatosFactura(){
            if(this.datosFacturacion.nombre != null && this.datosFacturacion.apellido != null && this.datosFacturacion.correoFactura != null){
                fr
                 .collection("usuarios")
                 .doc(auth.currentUser.uid)
                 .update({
                     datosFacturacion : this.datosFacturacion
                 })
                const temp = { datosFacturacion : this.datosFacturacion}
                this.setUsuario (temp);
                const pagarBox = document.getElementById("pagarBox");
                pagarBox.classList.remove("disabledbutton");
                this.selectFactura = false;
                this.datosLlenados = true;
            }else{
                await this.$swal({
                icon: "warning",
                title: "Debes llenar al menos los datos marcados (*)",
                text: "Antes de continuar con el pago debes de llenar todos los campos",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: false,
                confirmButtonText: 'Ok',
                });
            }
        },

        setLoaded(){
        this.loaded = true;
        window.paypal
            .Buttons({
            createOrder: (data, actions) => {
                return actions.order.create({
                purchase_units: [
                    {
                    description: this.details.description,
                    amount: {
                        currency_code: "MXN",
                        value: this.details.price
                    }
                    }
                ]
                });
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                this.data;
                this.paidFor = true;
                var date = new Date();
                if(this.details.price >= 250){
                    date = new Date(Date.now() + 86400000 * 30);
                }else{
                    date = new Date(Date.now() + 86400000 * 7);
                }

                await fr
                .collection("usuarios")
                .doc(auth.currentUser.uid)
                .update({
                    prime: 1,
                    FechaFinPrime: Date.parse(date),
                });

                await fr
                .collection("suscripciones")
                .doc(auth.currentUser.uid)
                .update({
                    estado: 1,
                    FechaFinPrime: Date.parse(date),
                });
                const date2 = new Date(Date.now());
                await fr
                .collection("relacionPagos")
                .doc()
                .set({
                    et_user_id: auth.currentUser.uid,
                    paypal_id: order.id,
                    payer_name: order.payer.name.given_name +" "+ order.payer.name.surname,
                    monto: this.details.price,
                    descripcion: this.details.description,
                    solicitudEstado: 0,
                    datosFacturacion: {
                        nombre: this.datosFacturacion.nombre +" " + this.datosFacturacion.apellido,
                        correoFactura: this.datosFacturacion.correoFactura,
                        codigoPostal: this.datosFacturacion.codigoPostal,
                        telefono: this.datosFacturacion.telefono,
                        calle: this.datosFacturacion.calle,
                        colonia: this.datosFacturacion.colonia,
                        noExterior: this.datosFacturacion.noExterior,
                        noInterior: this.datosFacturacion.noInterior,
                        estado: this.datosFacturacion.estado,
                        ciudad: this.datosFacturacion.ciudad,
                        rfc: this.datosFacturacion.rfc,
                        fechaRegistroPago: Date.parse(date2),
                    }
                });
                let tiempo = "";
                if(this.details.price == 250){
                    tiempo = "30 días"
                }else{
                    tiempo = "7 días"
                } 

                await fr
                .collection("mail")
                .add({
                    to: this.datosFacturacion.correoFactura,
                    template: {
                        name: "thanksSuscripcionMail",
                        data: {
                            tiempo : tiempo,
                            paypal_id : order.id,
                            precio : this.details.price,
                            total : this.details.price,
                            calle : this.datosFacturacion.calle,
                            colonia : this.datosFacturacion.colonia,
                            numeroE : this.datosFacturacion.noExterior,
                            estado : this.datosFacturacion.estado,
                            ciudad : this.datosFacturacion.ciudad,
                            correo : this.userDatail.correo,
                        }
                    },
                });
                //.then(() => console.log("EMAIL ENVIADO BB"));
                window.location.reload();
            },
            onError: err => {
                console.log(err);
            }
            })
            .render(this.$refs.paypal);
        },
        

    },

    watch:{
        band(value){
            if(value==true){
                this.userDatail = JSON.parse(sessionStorage.getItem("usuario"));

                if(this.datosFCarga!=null){
                    this.datosFacturacion = this.datosFCarga;
                    this.datosLlenados = true

                }

                const pagarBox = document.getElementById("pagarBox");
                if(this.datosLlenados==false){
                   pagarBox.classList.add("disabledbutton");
                }
                // LIVE KEY: AeO4C5IQuD85b2jD-7DP0gYMlk76419bPAcQ0lSoFUvEPL-YnI_L5fzE0bu4Ftg8mFusrnTLY99chDMw
                // SANDBOX KEY: Ad0fmAuwjbRk0r5vtavo-p5Ix681_vTGTJJOVJIsVZoqCxWsivUU2wsPtoFwC5m4cOU5C66j_v-7UQK9
                const script = document.createElement("script");
                script.src =
                "https://www.paypal.com/sdk/js?client-id=AeO4C5IQuD85b2jD-7DP0gYMlk76419bPAcQ0lSoFUvEPL-YnI_L5fzE0bu4Ftg8mFusrnTLY99chDMw&currency=MXN";
                script.addEventListener("load", this.setLoaded);
                document.body.appendChild(script);
            }
        }
    }
}
</script>

<style scoped>
.form-settings{
    box-shadow: 1px 5px 5px #6932a8!important;
}
.clear-btn{
    background: #ed723e!important;
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}

</style>