<template>
  <div>
    <div
      class="modal fade"
      id="vocherPreview"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Comprobante de pago</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="alert alert-dark" role="alert">
                  Registro del voucher: {{ voucherVisualizacion.fecha }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <img
                  :src="voucherVisualizacion.url"
                  alt="Imagen del Vocher"
                  class="img-thumbnail"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: 'ModalVoucher',
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState('generals',['voucherVisualizacion'])
  }
}
</script>